

import { Component, Prop, Vue, Emit } from 'nuxt-property-decorator';
import RouterLink from '~/components/atoms/text/RouterLink.vue';

type menuItemsModel = Array<{
  title: string,
  children: Array<{
    name: string,
    title: string,
    link: { patch: string } | string,
    externalLink: boolean,
    assetsIconFile: string,
  }>,
}>

@Component({
  components: {
    RouterLink,
  }
})
export default class HeaderSubMenuMolecule extends Vue {
  @Prop({ required: false }) menuItems: menuItemsModel

  @Emit()
  emitClickEvent(itemWasClickedStatus: boolean) {
    this.toggleSubMenuItemsVisibility();
    return itemWasClickedStatus;
  }

  subMenuItems = {
    visibility: false,
  }

  toggleSubMenuItemsVisibility() {
    this.subMenuItems.visibility = !this.subMenuItems.visibility;
  }

}

