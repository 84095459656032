import {
  InputFieldLabel,
  InputPlaceholder,
  LabelAdditives,
} from "~/operations/messages";

export default [
  {
    element: "input",
    id: "checkout-billing-company-input",
    variant: "bordered",
    type: "",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    label: `${InputFieldLabel.company} ${LabelAdditives.ifCommercial}`,
    placeholder: InputPlaceholder.company,
    emitTarget: "company",
  },
  {
    element: "select",
    id: "checkout-billing-salutation-input",
    label: InputFieldLabel.salutation,
    selected: "",
    options: [
      { name: "Bitte wählen", value: "" },
      { name: "Herr", value: "Herr" },
      { name: "Frau", value: "Frau" },
    ],
    disabled: false,
    supportMessage: "",
    emitTarget: "salutation",
  },
  {
    element: "input",
    variant: "bordered",
    type: "",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    id: "checkout-billing-fname-input",
    label: InputFieldLabel.firstName,
    placeholder: InputPlaceholder.firstName,
    emitTarget: "prename",
  },
  {
    element: "input",
    variant: "bordered",
    type: "",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    id: "checkout-billing-lastname-input",
    label: InputFieldLabel.lastName,
    placeholder: InputPlaceholder.lastName,
    emitTarget: "lastname",
  },
  {
    element: "input",
    variant: "bordered",
    type: "",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    id: "checkout-billing-street-input",
    label: InputFieldLabel.street,
    placeholder: InputPlaceholder.street,
    emitTarget: "street",
  },
  {
    element: "input",
    variant: "bordered",
    type: "",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    id: "checkout-billing-streetnumber-input",
    label: InputFieldLabel.houseNumber,
    placeholder: InputPlaceholder.houseNumber,
    emitTarget: "streetNumber",
  },
  {
    element: "input",
    variant: "bordered",
    type: "",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    id: "checkout-billing-zipcode-input",
    label: InputFieldLabel.zipcode,
    placeholder: InputPlaceholder.zipcode,
    emitTarget: "zipcode",
  },
  {
    element: "select",
    id: "checkout-billing-city-input",
    label: `${InputFieldLabel.location} (Zuerst PLZ eingeben)`,
    emitTarget: "city",
    // Disabled unless multiple cities are loaded
    disabled: true,
  },
  {
    element: "input",
    variant: "bordered",
    type: "",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    id: "checkout-billing-phone-input",
    label: InputFieldLabel.phone,
    placeholder: InputPlaceholder.phone,
    emitTarget: "phone",
  },
];
