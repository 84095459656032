var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:`ratings-display-molecule ratings-display-molecule-is--variant-${_vm.variant}`},[_c('div',{class:_vm.classNamesForRatingContainer},[_c('ul',_vm._l((5),function(n){return _c('li',{key:n},[_c('Icon',{class:`star star-is--variant-${_vm.variant}`,attrs:{"icon":_vm.returnStarIcon(_vm.returnPartnerRatinValueByRules(), n)}})],1)}),0),_vm._v(" "),(_vm.showRatingsLabel)?_c('EsyParagraph',[_vm._v("\n      "+_vm._s(_vm.showRatingsLabel)+"\n    ")]):_vm._e(),_vm._v(" "),_c('EsyParagraph',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showRatingsLabel && _vm.returnPartnerRatinValueByRules() > 0),expression:"!showRatingsLabel && returnPartnerRatinValueByRules() > 0"}]},[_vm._v("\n      "+_vm._s(_vm.returnPartnerRatinValueByRules())+"\n    ")])],1),_vm._v(" "),_c('div',{class:`ratings-quantity-container ratings-quantity-container--${_vm.hasRating}`},[_c('EsyParagraph',{class:`hasRating--${!_vm.hasRating}`,attrs:{"variant":"small"}},[_vm._v("\n      "+_vm._s(_vm.returnPartnerRatinCountByRules() == 0
          ? ""
          : _vm.returnPartnerRatinCountByRules())+"\n      "),(
          !_vm.ratingCountLabel &&
          _vm.ratingContext !== 'static' &&
          _vm.ratingContext !== 'comparsion-page-context'
        )?_c('RouterLink',{attrs:{"href":{ path: '/infos/bewertungen' },"externalLink":false,"title":"Zu den Kundenmeinungen","variant":"small","color":"deepBlue"}},[_vm._v("\n        esyoil-Bewertungen\n      ")]):[(Number(_vm.returnPartnerRatinCountByRules()) > 1)?[_vm._v("\n          Bewertungen\n        ")]:_vm._e(),_vm._v(" "),(Number(_vm.returnPartnerRatinCountByRules()) === 0)?[_vm._v("\n          noch keine Bewertungen\n        ")]:_vm._e(),_vm._v(" "),(Number(_vm.returnPartnerRatinCountByRules()) === 1)?[_vm._v("\n          Bewertung\n        ")]:_vm._e()]],2),_vm._v(" "),_c('div',[_c('ul',{class:`certificates-list certificates-list-hasRating--${!_vm.hasRating}`},[_c('ListItem',[(_vm.isRal)?_c('p',[_vm._v("RAL")]):_vm._e()])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }