import {
  InputFieldLabel,
  InputPlaceholder,
  LabelAdditives,
} from "~/operations/messages";

export default [
  {
    element: "input",
    variant: "bordered",
    type: "",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    id: "checkout-company-input",
    label: `${InputFieldLabel.company} ${LabelAdditives.ifCommercial}`,
    placeholder: InputPlaceholder.company,
    emitTarget: "company",
  },
  {
    element: "select",
    id: "checkout-salutation-input",
    label: InputFieldLabel.salutation,
    selected: "",
    options: [
      { name: "Bitte wählen", value: "" },
      { name: "Herr", value: "Herr" },
      { name: "Frau", value: "Frau" },
    ],
    disabled: false,
    supportMessage: "",
    emitTarget: "salutation",
  },
  {
    element: "input",
    variant: "bordered",
    type: "",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    id: "checkout-fname-input",
    label: InputFieldLabel.firstName,
    placeholder: InputPlaceholder.firstName,
    emitTarget: "prename",
  },
  {
    element: "input",
    variant: "bordered",
    type: "",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    id: "checkout-lastname-input",
    label: InputFieldLabel.lastName,
    placeholder: InputPlaceholder.lastName,
    emitTarget: "lastname",
  },
  {
    element: "autofillInput",
    variant: "bordered",
    type: "",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    id: "checkout-street-input",
    label: InputFieldLabel.street,
    placeholder: InputPlaceholder.street,
    emitTarget: "street",
  },
  {
    element: "input",
    variant: "bordered",
    type: "",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    id: "checkout-streetnumber-input",
    label: InputFieldLabel.houseNumber,
    placeholder: InputPlaceholder.houseNumber,
    emitTarget: "streetNumber",
  },
  {
    element: "input",
    variant: "",
    type: "",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    id: "checkout-zipcode-input",
    label: `${InputFieldLabel.zipcode} ${LabelAdditives.fixed}`,
    placeholder: InputPlaceholder.zipcode,
    emitTarget: "zipcode",
    disabled: true,
  },
  {
    element: "select",
    id: "checkout-city-input",
    label: InputFieldLabel.location,
    emitTarget: "city",
    // Disabled unless multiple cities are loaded
    disabled: true,
  },
  {
    element: "input",
    variant: "bordered",
    type: "",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    id: "checkout-district-input",
    label: `${InputFieldLabel.district} ${LabelAdditives.optional}`,
    placeholder: InputPlaceholder.district,
    emitTarget: "district",
  },
];
