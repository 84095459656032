
import { Component, Prop, Vue } from "nuxt-property-decorator";
import RouterLink from "~/components/atoms/text/RouterLink.vue";

@Component({
  components: {
    RouterLink,
  },
})
export default class RatingsDisplay extends Vue {
  @Prop({ default: "default" }) variant:
    | "default"
    | "static"
    | "priceComparisonRating";
  @Prop({ default: "default" }) ratingContext: string;
  @Prop() ratingValue: string;
  @Prop() ratingCount: string;
  @Prop() ratingValueLastYear: string;
  @Prop() ratingCountLastYear: string;
  @Prop() ratingCountLabel: string;
  @Prop({ default: false }) hasDealerRating: boolean;
  @Prop({ default: false }) isRal: boolean;
  @Prop({ default: false }) priceComparisonRating: boolean;
  @Prop({ default: false }) showRatingsLabel: string | boolean;

  // NOTE: esyoil is dispaying the partner rating differently, in different contextes according to specific rules and regulations.
  returnDefaultRating() {
    const FormattedRatingValue = Number(this.ratingValue);
    return FormattedRatingValue.toFixed(2);
  }
  returnPriceComparisonPageRating() {
    const FormattedRatingLastYearValue = Number(this.ratingValueLastYear);
    const FormattedRatingCount = Number(this.ratingCount);
    const FormattedRatingCountLastYear = Number(this.ratingCountLastYear);

    if (FormattedRatingCount <= 5) return "noch keine Bewertungen";
    if (FormattedRatingCount > 5) {
      if (FormattedRatingCountLastYear === 0) return "noch keine Bewertungen";
      if (FormattedRatingCountLastYear > 0) {
        return `${FormattedRatingLastYearValue.toFixed(2)}`;
      }
    }
  }

  returnPartnerRatinValueByRules() {
    if (this.ratingContext === "default") return this.returnDefaultRating();
    if (this.ratingContext === "comparsion-page-context")
      return this.returnPriceComparisonPageRating();
    if (this.ratingContext === "partner-page-context")
      return this.returnPartnerPageRating();
    if (this.ratingContext === "static") return this.returnDefaultRating();
    return this.returnPriceComparisonPageRating();
  }

  returnStarIcon(rating: string | number, starIndex: number) {
    if (rating === null || rating === 0) return "star-regular.svg";
    if (rating >= starIndex) return "star-solid.svg";
    if (rating >= starIndex - 0.5) return "star-half-alt-solid.svg";
    return "star-regular.svg";
  }

  returnPartnerPageRating() {
    return this.returnPriceComparisonPageRating();
  }

  returnPartnerPageRatingCount() {
    return this.returnPriceComparisonPageRatingCount();
  }

  get hasRating() {
    return (
      this.returnPriceComparisonPageRatingCount() ===
        "noch keine Bewertungen" && this.priceComparisonRating
    );
  }

  get classNamesForRatingContainer() {
    return {
      "rating-container": true,
      "is--rating-container--true": this.hasRating,
      [`is--ratings-count-${this.returnPartnerRatinValueByRules()}`]: true,
      "price-comparison--true": this.priceComparisonRating === true,
    };
  }

  returnPriceComparisonPageRatingCount() {
    const FormattedRatingCount = Number(this.ratingCount);
    const FormattedRatingCountLastYear = Number(this.ratingCountLastYear);
    let countingLabel = "esyoil Bewertungen";

    if (this.ratingCountLabel) countingLabel = this.ratingCountLabel;

    if (FormattedRatingCount <= 5) return "noch keine Bewertungen";
    if (FormattedRatingCount > 5) {
      if (FormattedRatingCountLastYear === 0) return "noch keine Bewertungen";
      if (FormattedRatingCountLastYear > 0) {
        return `(${FormattedRatingCount})`;
      }
    }
  }
  returnPartnerRatinCountByRules() {
    const FormattedRatingValue = Number(this.ratingValue);
    const FormattedRatingCount = Number(this.ratingCount).toLocaleString(
      "de-DE",
    );

    if (this.ratingContext === "default") {
      if (FormattedRatingValue > 0) return `${FormattedRatingCount}`;
    }
    if (this.ratingContext === "partner-page-context")
      return this.returnPartnerPageRatingCount();
    if (this.ratingContext === "comparsion-page-context")
      return this.returnPriceComparisonPageRatingCount();
    if (this.ratingContext === "static") return "";
    return `(${FormattedRatingCount})`;
  }
}
