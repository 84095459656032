
import { Jsonld } from "nuxt-jsonld";
import { scrollIntoView } from "seamless-scroll-polyfill";

import { Component, Vue, Watch, namespace } from "nuxt-property-decorator";
import FederalStates from "~/assets/data/federalStates.json";
import EsyH4 from "~/components/atoms/text/EsyH4.vue";
import RouterLink from "~/components/atoms/text/RouterLink.vue";
import AppStoreBadge from "~/components/molecules/display/badges/AppStoreButton.vue";
import FacebookIcon from "~/components/molecules/display/badges/FacebookIcon.vue";
import LinkedinIcon from "~/components/molecules/display/badges/LinkedinIcon.vue";
import PlayStoreBadge from "~/components/molecules/display/badges/PlayStoreButton.vue";
import TwitterIcon from "~/components/molecules/display/badges/TwitterIcon.vue";
import SummedRating from "~/components/molecules/display/ratings/SummedRating.vue";
import { PaymentTypes, Products } from "~/operations/messages";
import { decodeUrlForPlace, getMkLogin, getPkLogin } from "~/operations/shared";

const EsyoilStore = namespace("esyoil");

@Jsonld
@Component({
  components: {
    RouterLink,
    SummedRating,
    AppStoreBadge,
    PlayStoreBadge,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
    EsyH4,
  },
  methods: {
    decodeUrlForPlace,
  },
})
export default class Footer extends Vue {
  @EsyoilStore.Action("fetchEsyoilRatingData")
  fetchEsyoilRatingData: any;

  @EsyoilStore.State("companyRating")
  companyRating: { ratingValue: number; ratingCount: number };

  async fetch() {
    if (!this.companyRating.ratingCount) {
      await this.fetchEsyoilRatingData();
    }
  }

  @Watch("$route")
  scrollTop() {
    // internal navigation within the ortssite
    // window.scrollTo not working in Safari 17.1 version. use polyfill instead
    scrollIntoView(document.body, {
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  }

  jsonld() {
    return {
      "@context": "http://schema.org",
      "@graph": [
        {
          "@type": "WebPage",
          "@id": "https://www.esyoil.com/unternehmen/impressum",
        },
        {
          "@type": "Product",
          name: "esyoil",
          brand: {
            "@type": "Brand",
            name: "esyoil - Aktuelle Heizölpreise vergleichen und Heizöl kaufen",
          },
          review: {
            "@type": "Review",
            reviewRating: {
              "@type": "Rating",
              ratingValue: Number(
                this.$store.state.esyoil.companyRating.ratingValue,
              ),
              bestRating: 5,
            },
            author: {
              "@type": "Organization",
              name: "agital.online GmbH",
            },
          },
          aggregateRating: {
            "@type": "AggregateRating",
            bestRating: 5,
            worstRating: 1,
            ratingValue: Number(
              this.$store.state.esyoil.companyRating.ratingValue,
            ),
            ratingCount: Number(
              this.$store.state.esyoil.companyRating.ratingCount,
            ),
            itemReviewed: {
              "@type": "WebPage",
              name: this.$route.fullPath,
            },
          },
        },
      ],
    };
  }

  Products = Products;
  PaymentTypes = PaymentTypes;
  cities = [
    {
      row: [
        {
          name: `Berlin`,
          title: "Berlin",
          href: { path: decodeUrlForPlace("heizoelpreise-berlin-10115") },
          externalLink: false,
        },
        {
          name: `Frankfurt am Main`,
          title: "Frankfurt am Main",
          href: {
            path: decodeUrlForPlace(
              `heizoelpreise-${encodeURIComponent("frankfurt am main")}-60306`,
            ),
          },
          externalLink: false,
        },
        {
          name: `Essen`,
          title: "Essen",
          href: { path: decodeUrlForPlace("heizoelpreise-essen-45127") },
          externalLink: false,
        },
        {
          name: `Hannover`,
          title: "Hannover",
          href: { path: decodeUrlForPlace("heizoelpreise-hannover-30159") },
          externalLink: false,
        },
        {
          name: `Wuppertal`,
          title: "Wuppertal",
          href: { path: decodeUrlForPlace("heizoelpreise-wuppertal-42103") },
          externalLink: false,
        },
        {
          name: "Hamburg",
          title: "Hamburg",
          href: { path: decodeUrlForPlace("heizoelpreise-hamburg-20095") },
          externalLink: false,
        },
        {
          name: "Stuttgart",
          title: "Stuttgart",
          href: { path: decodeUrlForPlace("heizoelpreise-stuttgart-70173") },
          externalLink: false,
        },
        {
          name: "Leipzig",
          title: "Leipzig",
          href: { path: decodeUrlForPlace("heizoelpreise-leipzig-04103") },
          externalLink: false,
        },
        {
          name: "Nürnberg",
          title: "Nürnberg",
          href: { path: decodeUrlForPlace("heizoelpreise-nürnberg-90402") },
          externalLink: false,
        },
        {
          name: "Bielefeld",
          title: "Bielefeld",
          href: { path: decodeUrlForPlace("heizoelpreise-bielefeld-33602") },
          externalLink: false,
        },
        {
          name: "München",
          title: "München",
          href: { path: decodeUrlForPlace("heizoelpreise-münchen-80331") },
          externalLink: false,
        },
        {
          name: "Düsseldorf",
          title: "Düsseldorf",
          href: { path: decodeUrlForPlace("heizoelpreise-düsseldorf-40210") },
          externalLink: false,
        },
        {
          name: "Bremen",
          title: "Bremen",
          href: { path: decodeUrlForPlace("heizoelpreise-bremen-27568") },
          externalLink: false,
        },
        {
          name: "Duisburg",
          title: "Duisburg",
          href: { path: decodeUrlForPlace("heizoelpreise-duisburg-47051") },
          externalLink: false,
        },
        {
          name: "Bonn",
          title: "Bonn",
          href: { path: decodeUrlForPlace("heizoelpreise-bonn-53111") },
          externalLink: false,
        },
        {
          name: "Köln",
          title: "Köln",
          href: { path: decodeUrlForPlace("heizoelpreise-köln-50667") },
          externalLink: false,
        },

        {
          name: "Dortmund",
          title: "Dortmund",
          href: { path: decodeUrlForPlace("heizoelpreise-dortmund-44135") },
          externalLink: false,
        },

        {
          name: "Dresden",
          title: "Dresden",
          href: { path: decodeUrlForPlace("heizoelpreise-dresden-01067") },
          externalLink: false,
        },

        {
          name: "Bochum",
          title: "Bochum",
          href: { path: decodeUrlForPlace("heizoelpreise-bochum-44787") },
          externalLink: false,
        },

        {
          name: "Münster",
          title: "Münster",
          href: { path: decodeUrlForPlace("heizoelpreise-münster-48143") },
          externalLink: false,
        },
      ],
    },
  ];

  states = [
    {
      row: FederalStates,
    },
  ];

  menuItems: Array<object> = [
    {
      title: "Heizöl-Infos",
      links: [
        {
          name: `${PaymentTypes.guaranteedPayment}`,
          title: "Heizöl auf Raten kaufen",
          href: { path: "/infos/ratenkauf" },
          externalLink: false,
        },
        {
          name: "Bewertungen",
          title: "Unsere Kundenbewertungen im Überblick",
          href: { path: "/infos/bewertungen" },
          externalLink: false,
        },
        {
          name: "Ölpreis aktuell",
          title: "Erfahren Sie mehr über den aktuellen Ölpreis",
          href: { path: "/oelpreis" },
          externalLink: false,
        },
        {
          name: "Heizöl-Preishistorie",
          title: "Erfahren Sie mehr über die Ölpreis-Entwicklung",
          href: { path: "/services/historien-preisrechner" },
          externalLink: false,
        },
        {
          name: "Heizöl-News",
          title: "Erfahren Sie etwas über Neuigkeiten zum Thema Heizöl",
          href: { path: "/news" },
          externalLink: false,
        },
        {
          name: "CO2-Steuer",
          title: "Erhalten Sie Informationen zur CO2-Steuer",
          href: { path: "/infos/co2-steuer" },
          externalLink: false,
        },
        {
          name: "Hilfebereich",
          title:
            "Häufig gestellte Fragen werden in unserem Hilfebereich beantwortet",
          href: { path: "/infos/faq" },
          externalLink: false,
        },
        {
          name: "HVO-Heizöl",
          title: "Erhalten Sie Informationen zu HVO-Heizöl",
          href: { path: "/infos/hvo-heizoel" },
          externalLink: false,
        },
      ],
    },
    {
      title: "Service",
      links: [
        {
          name: "Benzinpreise",
          title: "Zu den Benzinpreisen",
          href: "/benzinpreise",
          externalLink: true,
        },
        {
          name: "Pelletspreise",
          title: "Zu den Pelletspreisen",
          href: "/pelletspreise",
          externalLink: true,
        },
        {
          name: "Tipps & Tricks",
          title: "Zu Tipps & Tricks von esyoil",
          href: { path: "/services/tipps-tricks" },
          externalLink: false,
        },
        {
          name: "Heizölverbrauch berechnen",
          title:
            "Berechnen Sie ihren Heizöl-Verbrauch mit unserem Heizöl-Verbrauchsrechner",
          href: { path: "/services/heizoelverbrauch" },
          externalLink: false,
        },
        {
          name: Products.mainApp,
          title: `Infos zu unserer ${Products.mainApp}`,
          href: { path: "/services/app" },
          externalLink: false,
        },
        {
          name: "Ölheizung",
          title: "Informationen zu Ölheizungen",
          href: { path: "/services/oelheizung" },
          externalLink: false,
        },
        {
          name: "Tipps zur Heizöllieferung",
          title: "Hier gibt es Tipps rund um die Heizöllieferung",
          href: { path: "/services/heizoellieferung" },
          externalLink: false,
        },
        {
          name: "Qualitätsmanagement",
          title: "Hier gibt es Infos zum Thema Qualitätsmanagement",
          href: { path: "/infos/qualitaetsmanagement" },
          externalLink: false,
        },
      ],
    },
    {
      title: "Konto",
      links: [
        {
          name: "Mein Konto",
          title: "Hier gelngst Du zu deinem Konto",
          href: getMkLogin(),
          externalLink: true,
        },
        {
          name: "Preiswecker / Newsletter",
          title: "Melde Dich für unseren Preiswecker und/oder Newsletter an",
          href: getMkLogin(),
          externalLink: true,
        },
        {
          name: "Händler-Konto",
          title: "Hier gelangst Du zu Deinem esyoil Händler-Konto",
          href: getPkLogin(),
          externalLink: true,
        },
      ],
    },
    {
      title: "esyoil",
      links: [
        {
          name: "Über uns",
          title: "Erfahre mehr über esyoil, als Unternehmen",
          href: { path: "/unternehmen/esyoil" },
          externalLink: false,
        },
        {
          name: "Jobs",
          title: "Erfahre mehr über Karriere-Möglichkeiten bei esyoil",
          href: "https://www.agital.online/career",
          externalLink: true,
          target: "_blank",
        },
        {
          name: "AGB",
          title: "Zu unseren Allgemeine Geschäfts- und Nutzungsbedingungen",
          href: { path: "/unternehmen/agb" },
          externalLink: false,
        },
        {
          name: "Impressum",
          title: "Zum Impressum",
          href: { path: "/unternehmen/impressum" },
          externalLink: false,
        },
        {
          name: "Presse",
          title: "Zu unserem Infoangebot",
          href: { path: "/unternehmen/presse" },
          externalLink: false,
        },
        {
          name: "Datenschutz",
          title: "Zu unserer Datenschutzauskunft",
          href: { path: "/unternehmen/datenschutz" },
          externalLink: false,
        },
      ],
    },
    {
      title: "Folgen Sie uns",
      links: [
        {
          name: "Über uns",
        },
      ],
    },
  ];
}
