import billingAddressFormDataDefault from "./formData/billingAddressFormData";
import birthDayFormDataDefault from "./formData/birthDayFormData";
import contactFormDataDefault from "./formData/contactFormData";
import deliveryAddressFormDataDefault from "./formData/deliveryAddressFormData";

export default {
  orderStatus: {
    success: {
      dealId: null,
      trustedShopLink: "",
      status: null,
      error: "",
      errorMessage: "",
    },
    showCheckboxes: false,
    buyBindingStep: false,
    checkedDSGVO: false,
    checkedAGB: false,
    checkedSEPA: false,
    validComparsionPrice: true,
    completed: false,
    deliveryDate: null,
    deliveryDateRaw: null,
    error: {
      checkedDSGVO: "",
      checkedSEPA: "",
      checkedAGB: "",
    },
    globalErrorMessage: "",
  },
  forms: {
    deliveryAddressFormData: deliveryAddressFormDataDefault,
    birthDayFormData: birthDayFormDataDefault,
    contactFormData: contactFormDataDefault,
    billingAddressFormData: billingAddressFormDataDefault,
    unloadingPointsForms: [],
  },
  ratePlanOptions: {
    results: [],
    payment_amount: 0,
    installment_number: 0,
    installment_amount: 0,
    last_installment_amount: 0,
    interest_rate: 0,
    payment_firstday: 0,
  },
  selection: {
    label: "",
    distributableAmount: 0,
    ownDistributedAmount: "",
    ownDistributedAmountError: "",
    common: {
      isDeliveryChargeActive: false,
      isDeliveryChargeIncluded: false,
      deliveryChargePrice: 0,
    },
    vat: {
      price: 0.0,
      price100L: 0.0,
    },
    deliveryPrice: {
      price: 0.0,
      price100L: 0.0,
    },
    nettoPrice: {
      price: 0.0,
      price100L: 0.0,
    },
    bruttoPrice: {
      price: 0.0,
      price100L: 0.0,
    },
  },
  autoCompletion: {
    placeNames: [],
    // Unused
    streetNames: [],
  },
  product: [],
  deliveryTime: [],
  heatingOilOrder: {
    dealer: {
      businessCustomerId: "",
      deliveryAreaId: "",
      legacyId: -1,
      rating: 0,
    },
    customer: {
      company: "",
      salutation: "",
      prename: "",
      lastname: "",
      street: "",
      streetNumber: "",
      city: "",
      district: "",
      zipcode: "",
      birthday: null,
      phone: "",
      mobile: "",
      noticeCustomerToDealer: "",
      mail: "",
      billingAddress: null,
      isloggedin: false,
      customerId: 0,
      otherUnloadingPoints: [],
      iban: "",
      ibanOwner: "",
    },
    order: {
      zipcode: "",
      amount: 0,
      prod: "",
      payment_type: "",
      unloading_points: 0,
      hose: "",
      short_vehicle: "",
      deliveryTimes: "",
      netto100lprice: 0.0,
      origin: "esyoil",
      ratepayData: {
        amount: null,
        annualPercentageRate: null,
        descriptor: "",
        interestAmount: null,
        interestRate: null,
        lastRate: null,
        monthlyDebitInterest: null,
        numberOfRates: null,
        numberOfRatesFull: null,
        paymentFirstday: null,
        rate: null,
        serviceCharge: null,
        totalAmount: null,
        transactionId: "",
      },
    },
  },
};
