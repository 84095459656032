
import { Component, namespace, Prop, Vue } from "nuxt-property-decorator";
import { ButtonText, Products } from "~/operations/messages";
import sendGtmEvent from "~/operations/send-gtm-event";
import RouterLink from "~/components/atoms/text/RouterLink.vue";

const CalculatorModule = namespace("calculator");

@Component({
  components: {
    RouterLink,
  },
})
export default class PriceComparingForm extends Vue {
  @Prop({ default: "small" }) variant: "small" | "bordered";
  @Prop({ default: "all" }) paymentType: string;

  ButtonText = ButtonText;

  userZipCode = "";
  userOilAmount: number | string = 3000;
  userZipCodeSupportMessage = "";
  userOilAmountSupportMessage = "";

  @CalculatorModule.Action("fetchAvailableOptions")
  fetchAvailableOptions: any;

  @CalculatorModule.State("availabilities")
  availabilities: any;

  mounted() {
    this.tryLocalStoragePreset();
  }

  setButtonVariant() {
    if (this.variant === "small") return "small";
    if (this.variant === "bordered") return "black";
  }

  tryLocalStoragePreset() {
    let userPresetZipCode: string = localStorage.getItem("userZipCode");

    if (userPresetZipCode !== null && userPresetZipCode.length === 5) {
      this.userZipCode = userPresetZipCode;
    }

    let userPresetOilAmount = Number(localStorage.getItem("userOilAmount"));
    if (userPresetOilAmount !== null && userPresetOilAmount >= 500)
      this.userOilAmount = userPresetOilAmount;
  }

  setLocalStoragePreset(zipcode: string, oilAmount: number) {
    if (zipcode.length === 5) {
      localStorage.setItem("userZipCode", zipcode);
    }
    localStorage.setItem("userOilAmount", oilAmount.toString());
  }

  async redirectToComparsionPage() {
    this.setLocalStoragePreset(this.userZipCode, Number(this.userOilAmount));

    const zipcodeErrorMsg = "Ungültige Postleitzahl";

    if (this.userZipCode.length !== 5) {
      this.userZipCodeSupportMessage = zipcodeErrorMsg;
    }

    if (this.userZipCode === null) this.userZipCodeSupportMessage = zipcodeErrorMsg;

    if (this.userZipCode === "") this.userZipCodeSupportMessage = zipcodeErrorMsg;

    if (Number(this.userOilAmount) === 0)
      this.userOilAmountSupportMessage = "Minimum 500 Liter";
    if (Number(this.userOilAmount) <= 500)
      this.userOilAmountSupportMessage = "Minimum 500 Liter";
    if (Number(this.userOilAmount) >= 32000)
      this.userOilAmountSupportMessage = "Maximum 32.000 Liter";
    if (this.userOilAmount === null) this.userZipCodeSupportMessage = "Minimum 500 Liter";
    if (this.userOilAmount === "") this.userZipCodeSupportMessage = "Minimum 500 Liter";
    if (Number(this.userOilAmount) >= 500 && Number(this.userOilAmount) <= 32000)
      this.userOilAmountSupportMessage = "";

    if (
      this.userZipCode.length === 5 &&
      this.userOilAmount >= 500 &&
      this.userOilAmount <= 32000
    ) {
      await this.fetchAvailableOptions({
        zipcode: this.userZipCode,
        amount: this.userOilAmount,
      });

      this.$router.push({
        path: "/bestellung",
        query: {
          zipcode: this.userZipCode.toString(),
          amount: this.userOilAmount.toString(),
          unloading_points: "1",
          payment_type: this.availablePayment,
          prod: "normal",
          hose: "fortyMetre",
          short_vehicle: "withTrailer",
          deliveryTimes: "normal",
        },
      });
    }

    sendGtmEvent(this, {
      category: "Navigation",
      label: "Nav CTA PV",
      action: "submit",
    });
  }

  get availablePayment() {
    return this.availabilities.paymentTypes.includes(this.paymentType)
      ? this.paymentType
      : this.availabilities.paymentTypes[0];
  }
}
