import { InputFieldLabel, LabelAdditives } from "~/operations/messages";
import dayjs from "dayjs";

export default [
  {
    element: "input",
    variant: "bordered",
    type: "date",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    id: "checkout-birthday-input",
    label: `${InputFieldLabel.birthday} ${LabelAdditives.invoiceRecipient}`,
    placeholder: getActualDateSubtract18Years(),
    emitTarget: "birthday",
  },
  {
    element: "input",
    variant: "bordered",
    type: "text",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    id: "checkout-account-owner-input",
    label: `Bankkonto Inhaber`,
    placeholder: "Ihr Name",
    emitTarget: "ibanOwner",
  },
  {
    element: "input",
    variant: "bordered",
    type: "text",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    id: "checkout-iban-input",
    label: `Bankkonto IBAN`,
    placeholder: "Ihre IBAN",
    emitTarget: "iban",
  },
];

function getActualDateSubtract18Years() {
  const d = new Date();
  d.setFullYear(d.getFullYear() - 18);

  return dayjs(d).format("DD.MM.YYYY");
}
