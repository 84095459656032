import {
  InputFieldLabel,
  InputPlaceholder,
  LabelAdditives,
} from "~/operations/messages";

export default [
  {
    element: "input",
    variant: "bordered",
    type: "tel",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    id: "checkout-phone-input",
    label: InputFieldLabel.phone,
    placeholder: InputPlaceholder.phone,
    emitTarget: "phone",
  },
  {
    element: "input",
    variant: "bordered",
    type: "tel",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    id: "checkout-mobile-input",
    label: `${InputFieldLabel.additionalPhone} ${LabelAdditives.optional}`,
    placeholder: InputPlaceholder.additionalPhone,
    emitTarget: "mobile",
  },
  {
    element: "input",
    variant: "bordered",
    type: "",
    assetsIconFile: "",
    value: "",
    supportMessage: "",
    id: "checkout-mail-input",
    label: InputFieldLabel.mail,
    placeholder: InputPlaceholder.mail,
    emitTarget: "mail",
  },
];
