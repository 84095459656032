
import { Component, Vue } from "nuxt-property-decorator";
import RouterLink from "~/components/atoms/text/RouterLink.vue";

@Component({
  components: {
    RouterLink,
  },
})
export default class FacebookIcon extends Vue {}
